/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/24/2021
 * @Example
 */

export const types = {
    W09F2090_GET_DATA_GRID: "W09F2090_GET_DATA_GRID",
    W09F2090_GET_CBO_APP_STATUS: "W09F2090_GET_CBO_APP_STATUS",
    W09F2090_GET_CBO_APP_STATUS_SUCCESS: "W09F2090_GET_CBO_APP_STATUS_SUCCESS",
    W09F2090_GET_CBO_DIVISION: "W09F2090_GET_CBO_DIVISION",
    W09F2090_GET_CBO_DIVISION_SUCCESS: "W09F2090_GET_CBO_DIVISION_SUCCESS",
    W09F2090_GET_CBO_DEPARTMENT: "W09F2090_GET_CBO_DEPARTMENT",
    W09F2090_GET_CBO_DEPARTMENT_SUCCESS: "W09F2090_GET_CBO_DEPARTMENT_SUCCESS",
    W09F2090_DELETE_ROW: "W09F2090_DELETE_ROW",
};

export function getDataGrid(params, cb) {
    return {
        type: types.W09F2090_GET_DATA_GRID,
        params,
        cb
    };
}

export function getCboAppStatus(params, cb) {
    return {
        type: types.W09F2090_GET_CBO_APP_STATUS,
        params,
        cb
    };
}


export function getCboDivision(cb) {
    return {
        type: types.W09F2090_GET_CBO_DIVISION,
        cb
    };
}


export function getCboDepartment(params, cb) {
    return {
        type: types.W09F2090_GET_CBO_DEPARTMENT,
        params,
        cb
    };
}

export function deleteRow(params, cb) {
    return {
        type: types.W09F2090_DELETE_ROW,
        params,
        cb
    };
}