/**
 * @copyright 2019 @ DigiNet
 * @author DINHTHIEN
 * @create 16/3/2020
 * @Example
 */

import Api from "../../../services/api";
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2152_actions";
import { delay } from "redux-saga";

export default function W09F2152Sagas() {
    return [
        watchGetCboCode(),
    ];
}

export function* getCboCode(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2152/load-cbo-code");
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo code error",e);
    }
}

export function* watchGetCboCode() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2152_LOAD_CBO_CODE, getCboCode);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
