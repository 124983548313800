
export const types = {
    W09F2081_GET_DYNAMIC_COLUMN_SALARY: "W09F2080_GET_DYNAMIC_COLUMN_SALARY",
    W09F2081_GET_DYNAMIC_COLUMN_SALARY_SUCCESS: "W09F2080_GET_DYNAMIC_COLUMN_SALARY_SUCCESS",
    W09F2081_LOAD_GRID: "W09F2081_LOAD_GRID",
    W09F2081_CHOOSE: "W09F2081_CHOOSE",
};

export function getDynamicColumnSalary(params,cb) {
    return {
        type: types.W09F2081_GET_DYNAMIC_COLUMN_SALARY,
        params,
        cb
    };
}

export function loadGrid(params,cb) {
    return {
        type: types.W09F2081_LOAD_GRID,
        params,
        cb
    };
}

export function choose(params,cb) {
    return {
        type: types.W09F2081_CHOOSE,
        params,
        cb
    }
}