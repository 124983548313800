/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 5/7/2021
 * @Example 
 */

import {types} from "./W09F2033_actions";

const initialState = {
    getSalCoefficients: [],
    getBaseSalaries: [],
    getCboReasons: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2033_GET_SALCOEFFICIENT_CAPTION_SUCCESS: {
            return {
                ...state,
                getSalCoefficients: action.data
            };
        }
        case types.W09F2033_GET_BASE_SALARY_CAPTION_SUCCESS: {
            return {
                ...state,
                getBaseSalaries: action.data
            };
        }
        case types.W09F2033_GET_CBO_REASON_SUCCESS: {
            return {
                ...state,
                getCboReasons: action.data
            };
        }
        default:
            return state;
    }
}
