import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2023_actions";
import { delay } from "redux-saga";

export default function W09F2023Sagas() {
    return [watchGetForm()];
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2023/load-form", data.params);
        if (response && response.data) {
            yield put({ type: types.W09F2023_GET_FROM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form data error");
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2023_GET_FROM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
