/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/24/2020
 * @Example
 */

import Api                             from "../../../services/api";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types}                         from "./W09F2032_actions";
import {delay}                         from "redux-saga";

export default function W09F2032Sagas() {
    return [
        watchGetCaption(),
        watchGetCboCode(),
        watchGetCboReason(),
        watchGetForm(),
        watchAddData(),
        watchEditData(),
    ];
}

export function* getCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2031/load-caption");
        if (response && response.data) {
            yield put({type: types.W09F2032_GET_CAPTION_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get caption error", e);
    }
}

export function* watchGetCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2032_GET_CAPTION, getCaption);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getCboCode(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2152/load-cbo-code");
        if (response && response.data) {
            yield put({type: types.W09F2032_GET_CBO_CODE_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo code error", e);
    }
}

export function* watchGetCboCode() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2032_GET_CBO_CODE, getCboCode);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboReason(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2032/load-combo-reason");
        if (response && response.data) {
            yield put({type: types.W09F2032_GET_CBO_REASON_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo reason error", e);
    }
}

export function* watchGetCboReason() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2032_GET_CBO_REASON, getCboReason);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2032/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error", e);
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2032_GET_DATA_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* editData(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2032/edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit data error",e);
    }
}

export function* watchEditData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2032_EDIT_DATA, editData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* addData(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2032/add", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("add data error",e);
    }
}

export function* watchAddData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2032_ADD_DATA, addData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
