/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/29/2020
 * @Example 
 */

export const types = {
    W09F2152_LOAD_CBO_CODE: "W09F2152_LOAD_CBO_CODE",
    W09F2152_LOAD_CBO_CODE_SUCCESS: "W09F2152_LOAD_CBO_CODE_SUCCESS",
   
};

export function getCboCode(cb) {
    return {
        type: types.W09F2152_LOAD_CBO_CODE,
        cb
    };
}
