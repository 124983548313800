/**
 * @copyright 2019 @ DigiNet
 * @author THIEN
 * @create 14/3/2020
 * @Example
 */

export const types = {
    W09F2080_LOAD_CBO_APP_STATUS: "W09F2080_LOAD_CBO_APP_STATUS",
    W09F2080_LOAD_CBO_APP_STATUS_SUCCESS: "W09F2080_LOAD_CBO_APP_STATUS_SUCCESS",
    W09F2080_LOAD_CBO_DEPARTMENT: "W09F2080_LOAD_CBO_DEPARTMENT",
    W09F2080_LOAD_CBO_DEPARTMENT_SUCCESS: "W09F2080_LOAD_CBO_DEPARTMENT_SUCCESS",
    W09F2080_LOAD_CBO_EMPLOYEE: "W09F2080_LOAD_CBO_EMPLOYEE",
    W09F2080_LOAD_CBO_EMPLOYEE_SUCCESS: "W09F2080_LOAD_CBO_EMPLOYEE_SUCCESS",
    W09F2080_LOAD_CBO_PROJECT: "W09F2080_LOAD_CBO_PROJECT",
    W09F2080_LOAD_CBO_PROJECT_SUCCESS: "W09F2080_LOAD_CBO_PROJECT_SUCCESS",

    W09F2080_GET_DATA: "W09F2080_GET_DATA",
    W09F2080_GET_DATA_SUCCESS: "W09F2080_GET_DATA_SUCCESS",
    W09F2080_DELETE_DATA: "W09F2080_DELETE_DATA",
    W09F2080_LOAD_FORM: "W09F2080_LOAD_FORM",
    W09F2080_LOAD_FORM_POPUP: "W09F2080_LOAD_FORM_POPUP",
    W09F2080_CHECK_REQUIRED_FIELD: "W09F2080_CHECK_REQUIRED_FIELD",
    W09F2080_CHECK_REQUIRED_FIELD_SUCCESS: "W09F2080_CHECK_REQUIRED_FIELD_SUCCESS",
    W09F2080_SAVE: "W09F2080_SAVE",
    W09F2080_UPDATE: "W09F2080_UPDATE",
    W09F2080_GET_DYNAMIC_COLUMN_SALARY: "W09F2080_GET_DYNAMIC_COLUMN_SALARY",
    W09F2080_GET_DYNAMIC_COLUMN_SALARY_SUCCESS: "W09F2080_GET_DYNAMIC_COLUMN_SALARY_SUCCESS",
    W09F2080_LOAD_CBO_REASON: "W09F2080_LOAD_CBO_REASON",
    W09F2080_LOAD_CBO_REASON_SUCCESS: "W09F2080_LOAD_CBO_REASON_SUCCESS",
    W09F2080_GET_CBO_DIRECT_MANAGER: "W09F2080_GET_CBO_DIRECT_MANAGER",
};

export function getCboDirectManagers(params, cb) {
    return {
        type: types.W09F2080_GET_CBO_DIRECT_MANAGER,
        params,
        cb
    };
}


export function loadForm(params, cb) {
    return {
        type: types.W09F2080_LOAD_FORM,
        params,
        cb
    };
}

export function loadFormPopup(params, cb) {
    return {
        type: types.W09F2080_LOAD_FORM_POPUP,
        params,
        cb
    };
}

export function getRequiredFields(cb) {
    return {
        type: types.W09F2080_CHECK_REQUIRED_FIELD,
        cb
    };
}

export function save(params, cb) {
    return {
        type: types.W09F2080_SAVE,
        params,
        cb
    };
}

export function update(params, cb) {
    return {
        type: types.W09F2080_UPDATE,
        params,
        cb
    };
}

export function loadData(params, cb) {
    return {
        type: types.W09F2080_GET_DATA,
        params,
        cb
    };
}

export function loadCboAppStatus(params, cb) {
    return {
        type: types.W09F2080_LOAD_CBO_APP_STATUS,
        params,
        cb
    };
}

export function loadCboDeparments(params, cb) {
    return {
        type: types.W09F2080_LOAD_CBO_DEPARTMENT,
        params,
        cb
    };
}

export function loadCboEmployees(params, cb) {
    return {
        type: types.W09F2080_LOAD_CBO_EMPLOYEE,
        params,
        cb
    };
}

export function loadCboProjects(params, cb) {
    return {
        type: types.W09F2080_LOAD_CBO_PROJECT,
        params,
        cb
    };
}

export function deleteDataGrid(params, cb) {
    return {
        type: types.W09F2080_DELETE_DATA,
        params,
        cb
    };
}

export function getDynamicColumnSalary(params,cb) {
    return {
        type: types.W09F2080_GET_DYNAMIC_COLUMN_SALARY,
        params,
        cb
    };
}

export function getCboReasons(params,cb) {
    return {
        type: types.W09F2080_LOAD_CBO_REASON,
        params,
        cb
    };
}