/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 13/02/2020
 * @Example
 */

import Api from "../../../services/api";
import { put, cancel, take, takeLatest } from "redux-saga/effects";
import { delay } from "redux-saga";
import {types} from "./W09F2071_actions"

export default function W09F2071Sagas() {
    return [watchGetRewardCaption(),]
}

export function* getRewardCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2070/load-reward-caption', data.params);
        if (response && response.data) {
            yield put({type: types.W09F2071_GET_REWARD_CAPTION_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else{
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get error');
    }

}

export function* watchGetRewardCaption() {
    while (true){
        const watcher = yield takeLatest(types.W09F2071_GET_REWARD_CAPTION, getRewardCaption);
        yield take(['LOGOUT','NETWORK']);
        yield cancel(watcher);
    }
}