/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/26/2020
 * @Example 
 */

import { types } from "./W09F2031_actions";
const initialState = {
  getCaption: [],
  getCboCode: [],
  getCaptionNew: [],
  getCboStatusNew: [],
  getCboCodeNew: [],
};

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case types.W09F2031_GET_CAPTION_SUCCESS: {
      return {
        ...state,
        getCaption: action.data
      };
    }
    case types.W09F2031_LOAD_CBO_CODE_SUCCESS: {
      return {
        ...state,
        getCboCode: action.data
      };
    }
    case types.W09F2031_GET_CAPTION_NEW_SUCCESS: {
      return { ...state, getCaptionNew: action.data };
    }
    case types.W09F2031_GET_CBO_STATUS_NEW_SUCCESS: {
      return { ...state, getCboStatusNew: action.data };
    }
    case types.W09F2031_LOAD_CBO_CODE_NEW_SUCCESS: {
      return { ...state, getCboCodeNew: action.data };
    }
    default:
      return state;
  }
}
