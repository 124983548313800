/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/26/2020
 * @Example
 */
export const types = {
  W09F2031_GET_CAPTION: "W09F2031_GET_CAPTION",
  W09F2031_GET_CAPTION_SUCCESS: "W09F2031_GET_CAPTION_SUCCESS",
  W09F2031_LOAD_CBO_CODE: "W09F2031_LOAD_CBO_CODE",
  W09F2031_LOAD_CBO_CODE_SUCCESS: "W09F2031_LOAD_CBO_CODE_SUCCESS",
  W09F2031_LOAD_FORM_NEW: "W09F2031_LOAD_FORM_NEW",
  W09F2031_CHECK_FORM: "W09F2031_CHECK_FORM",
  W09F2031_GET_CBO_STATUS_NEW: "W09F2031_GET_CBO_STATUS_NEW",
  W09F2031_GET_CBO_STATUS_NEW_SUCCESS: "W09F2031_GET_CBO_STATUS_NEW_SUCCESS",
  W09F2031_LOAD_CBO_CODE_NEW: "W09F2031_LOAD_CBO_CODE_NEW",
  W09F2031_LOAD_CBO_CODE_NEW_SUCCESS: "W09F2031_LOAD_CBO_CODE_NEW_SUCCESS",
  W09F2031_GET_CAPTION_NEW: "W09F2031_GET_CAPTION_NEW",
  W09F2031_GET_CAPTION_NEW_SUCCESS: "W09F2031_GET_CAPTION_NEW_SUCCESS",
};

export function getCaption(cb) {
  return {
    type: types.W09F2031_GET_CAPTION,
    cb
  };
}

export function getCboCode(cb) {
  return {
    type: types.W09F2031_LOAD_CBO_CODE,
    cb
  };
}

export function loadFormNew(params, cb) {
  return {
    type: types.W09F2031_LOAD_FORM_NEW,
    params,
    cb
  };
}

export function loadCheckForm(cb) {
  return {
    type: types.W09F2031_CHECK_FORM,
    cb
  };
}

export function getCboStatusNew(params, cb) {
  return {
    type: types.W09F2031_GET_CBO_STATUS_NEW,
    params,
    cb
  };
}

export function getCboCodeNew(cb) {
  return {
    type: types.W09F2031_LOAD_CBO_CODE_NEW,
    cb
  };
}

export function getCaptionNew(cb) {
  return {
    type: types.W09F2031_GET_CAPTION_NEW,
    cb
  };
}


