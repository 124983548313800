/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 13/02/2020
 * @Example
 */

import {types} from "./W09F2071_actions"

const initialState = {
    getRewardCaption: []
};

export default function(state = initialState,action = {}) {
    if(action.type === types.W09F2071_GET_REWARD_CAPTION_SUCCESS)
    {
        return {
            ...state,
            getRewardCaption: action.data
        }
    }
    return state
}