/**
 * @copyright 2021 @ DigiNet
 * @author XUANLOC
 * @create 28/9/2021
 * @Example
 */

export const types = {
    W09F2092_GET_DYNAMIC_COLUMN: 'W09F2092_GET_DYNAMIC_COLUMN',
};

export function getDynamicColumn(params, cb) {
    return {
        type: types.W09F2092_GET_DYNAMIC_COLUMN,
        params,
        cb,
    };
}