/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 5/7/2021
 * @Example
 */

import Api                             from "../../../services/api";
import {cancel, put, take, takeLatest} from "redux-saga/effects";
import {types}                         from "./W09F2033_actions";
import {delay}                         from "redux-saga";

export default function W09F2033Sagas() {
    return [
        watchGetBaseSalaryCaption(),
        watchGetSalcoefficientCaption(),
        watchGetCboReason(),
        watchAdd(),
        watchEdit(),
        watchGetForm(),
    ];
}

export function* getBaseSalaryCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2033/load-base-salary-caption");
        if (response && response.data) {
            yield put({type: types.W09F2033_GET_BASE_SALARY_CAPTION_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get base salary caption error");
    }
}

export function* watchGetBaseSalaryCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2033_GET_BASE_SALARY_CAPTION, getBaseSalaryCaption);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getSalcoefficientCaption(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2033/Load-salcoefficient-caption");
        if (response && response.data) {
            yield put({type: types.W09F2033_GET_SALCOEFFICIENT_CAPTION_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get get salcoefficient caption error");
    }
}

export function* watchGetSalcoefficientCaption() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2033_GET_SALCOEFFICIENT_CAPTION, getSalcoefficientCaption);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboReason(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2032/load-combo-reason");
        if (response && response.data) {
            yield put({type: types.W09F2033_GET_CBO_REASON_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo reason error");
    }
}

export function* watchGetCboReason() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2033_GET_CBO_REASON, getCboReason);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2033/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2033_GET_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* add(data) {
    try {
        yield delay(300);
        const response = yield Api.post("/w09f2033/add", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("add data error");
    }
}

export function* watchAdd() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2033_ADD, add);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* edit(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2033/edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("edit data error");
    }
}

export function* watchEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2033_EDIT, edit);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

