/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/11/2021
 * @Example
 */

export const types = {
  W09F2205_GET_CBO_ADDRESS:"W09F2205_GET_CBO_ADDRESS",
  W09F2205_GET_LIST_RELATIVE: "W09F2205_GET_LIST_RELATIVE",
  W09F2205_GET_FORM:"W09F2205_GET_FORM",
  W09F2205_UPDATE:"W09F2205_UPDATE",
};

export function getCboAddress(params,cb) {
  return {
    type: types.W09F2205_GET_CBO_ADDRESS,
    cb,
    params
  };
}

export function getForm(params,cb) {
  return {
    type: types.W09F2205_GET_FORM,
    cb,
    params
  };
}

export function getListRelative(params, cb) {
  return {
    type: types.W09F2205_GET_LIST_RELATIVE,
    cb,
    params
  };
}

export function update(params,cb) {
  return {
    type: types.W09F2205_UPDATE,
    cb,
    params
  };
}


