/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/24/2021
 * @Example
 */

import { types } from "./W09F2090_actions";

const initialState = {
    getCboAppStatus: [],
    getCboDepartments: [],
    getCboDivisions:[],
};

export default function(state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2090_GET_CBO_APP_STATUS_SUCCESS: {
            return { ...state, getCboAppStatus: action.data };
        }
        case types.W09F2090_GET_CBO_DEPARTMENT_SUCCESS: {
            return { ...state, getCboDepartments: action.data };
        }
        case types.W09F2090_GET_CBO_DIVISION_SUCCESS: {
            return { ...state, getCboDivisions: action.data };
        }
        default:
            return state;
    }
}
