/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/25/2020
 * @Example
 */

import {types} from "./W09F2150_actions";

const initialState = {
    getDataGrid:             [],
    getForm:                 [],
    getDynamicFields:        [],
    getCaption:        [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2150_LOAD_GRID_SUCCESS: {
            return {
                ...state,
                getDataGrid: action.data
            };
        }
        case types.W09F2150_GET_FORM_SUCCESS: {
            return {
                ...state,
                getForm: action.data
            };
        }
        case types.W09F2150_LOAD_DYNAMIC_FIELD_SUCCESS: {
            return {
                ...state,
                getDynamicFields: action.data
            };
        }
        case types.W09F2150_LOAD_CAPTION_SUCCESS: {
            return {
                ...state,
                getCaption: action.data
            };
        }
        default:
            return state;
    }
}
