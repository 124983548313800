/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/26/2020
 * @Example 
 */

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2031_actions";
import { delay } from "redux-saga";

export default function W09F2031Sagas() {
  return [
    watchGetCaption(),
    watchGetCboCode(),
    watchLoadFormNew(),
    watchApiCheck(),
    watchLoadCboApprovalStatus(),
    watchGetCboCodeNew(),
    watchGetCaptionNew(),
  ];
}

export function* getCaption(data) {
  try {
    yield delay(300);
    const response = yield Api.get("/w09f2031/load-caption", data.params);
    if (response && response.data) {
      yield put({ type: types.W09F2031_GET_CAPTION_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get Caption error", e);
  }
}

export function* watchGetCaption() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2031_GET_CAPTION, getCaption);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

//get data grid
export function* getCboCode(data) {
  try {
    yield delay(300);
    const response = yield Api.get("/w09f2152/load-cbo-code", data.params);
    if (response && response.data) {
      yield put({
        type: types.W09F2031_LOAD_CBO_CODE_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get cbo code error", e);
  }
}

export function* watchGetCboCode() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2031_LOAD_CBO_CODE, getCboCode);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

//load form
export function* loadFormNew(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f2031/load-form", data.params);
    if (response && response.data) {
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("load form error", e);
  }
}

export function* watchLoadFormNew() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2031_LOAD_FORM_NEW, loadFormNew);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

//check
export function* loadCheckForm(data) {
  try {
    yield delay(300);
    const response = yield Api.get("/w09f2031/check");
    if (response && response.data) {
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("load check error", e);
  }
}

export function* watchApiCheck() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2031_CHECK_FORM, loadCheckForm);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

//load approval status
export function* loadCboApprovalStatus(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f2031/load-combo", data.params);
    if (response && response.data) {
      yield put({ type: types.W09F2031_GET_CBO_STATUS_NEW_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("load cbo approval status error", e);
  }
}

export function* watchLoadCboApprovalStatus() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2031_GET_CBO_STATUS_NEW, loadCboApprovalStatus);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

//load column
export function* getCboCodeNew(data) {
  try {
    yield delay(300);
    const response = yield Api.get("/w09f2031/load-collumn");
    if (response && response.data) {
      yield put({ type: types.W09F2031_LOAD_CBO_CODE_NEW_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("load column error", e);
  }
}

export function* watchGetCboCodeNew() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2031_LOAD_CBO_CODE_NEW, getCboCodeNew);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

//load column
export function* getCaptionNew(data) {
  try {
    yield delay(300);
    const response = yield Api.get("/w09f2031/load-caption");
    if (response && response.data) {
      yield put({ type: types.W09F2031_GET_CAPTION_NEW_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("load caption error", e);
  }
}

export function* watchGetCaptionNew() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2031_GET_CAPTION_NEW, getCaptionNew);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}
