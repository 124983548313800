/**
 * @copyright 2019 @ DigiNet
 * @author THIEN
 * @create 14/3/2020
 * @Example
 */

import { types } from "./W09F2080_actions";

const initialState = {
    getCboAppStatus: [],
    getCboDeparments: [],
    getCboEmployees:[],
    getCboProjects:[],
    getData: [],
    getRequiredFields: [],
    getCboReasons: [],
};

export default function(state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2080_GET_DATA_SUCCESS: {
            return { ...state, getData: action.data };
        }
        case types.W09F2080_LOAD_CBO_APP_STATUS_SUCCESS: {
            return { ...state, getCboAppStatus: action.data };
        }
        case types.W09F2080_LOAD_CBO_DEPARTMENT_SUCCESS: {
            return { ...state, getCboDepartments: action.data };
        }
        case types.W09F2080_LOAD_CBO_EMPLOYEE_SUCCESS: {
            return { ...state, getCboEmployees: action.data };
        }
        case types.W09F2080_LOAD_CBO_PROJECT_SUCCESS: {
            return { ...state, getCboProjects: action.data };
        }
        case types.W09F2080_CHECK_REQUIRED_FIELD_SUCCESS: {
            return {...state, getRequiredFields: action.data};
        }
        case types.W09F2080_LOAD_CBO_REASON_SUCCESS: {
            return {...state, getCboReasons: action.data};
        }
        default:
            return state;
    }
}
