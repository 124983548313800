/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/11/2021
 * @Example
 */

export const types = {
    W09F2210_GET_LIST_RELATIVE        : "W09F2210_GET_LIST_RELATIVE",
    W09F2210_GET_LIST_RELATIVE_SUCCESS: "W09F2210_GET_LIST_RELATIVE_SUCCESS",
    W09F2210_GET_DeTAIL                 : "W09F2210_GET_DeTAIL",
    W09F2210_GET_GENDER               : "W09F2210_GET_GENDER",
    W09F2210_GET_GENDER_SUCCESS       : "W09F2210_GET_GENDER_SUCCESS",
    W09F2210_DELETE                   : "W09F2210_DELETE",
    W09F2210_SAVE                   : "W09F2210_SAVE",
};

export function getListRelative(params, cb) {
    return {
        type: types.W09F2210_GET_LIST_RELATIVE,
        cb,
        params
    };
}

export function getDetail(params, cb) {
    return {
        type: types.W09F2210_GET_DeTAIL,
        cb,
        params
    };
}

export function deleteData(params, cb) {
    return {
        type: types.W09F2210_DELETE,
        cb,
        params
    };
}

export function getGender(cb) {
    return {
        type: types.W09F2210_GET_GENDER,
        cb,
    };
}


export function save(params, cb) {
    return {
        type: types.W09F2210_SAVE,
        cb,
        params
    };
}


