/**
 * @copyright 2019 @ DigiNet
 * @author DINHTHIEN
 * @create 16/3/2020
 * @Example
 */

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2080_actions";
import { delay } from "redux-saga";

export default function W09F2080Sagas() {
    return [
        watchGetCboAppStatus(),
        watchGetCboDepartments(),
        watchGetData(),
        watchDeleteData(),
        watchGetCboProjects(),
        watchGetCboEmployees(),
        watchGetForm(),
        watchGetFormPopup(),
        watchGetRequiredFields(),
        watchSave(),
        watchUpdate(),
        watchGetDynamicColumnSalary(),
        watchGetCboReasons(),
        watchGetCboDirectManager(),
    ];
}

export function* getData(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2080/load-form", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F2080_GET_DATA_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get data error");
    }
}

export function* watchGetData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_GET_DATA, getData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboAppStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2080/load-cbo-app-status", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F2080_LOAD_CBO_APP_STATUS_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get approval status error");
    }
}

export function* watchGetCboAppStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_LOAD_CBO_APP_STATUS, getCboAppStatus);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboDepartments(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/standard/load-cbo-department", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F2080_LOAD_CBO_DEPARTMENT_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get approval status error");
    }
}

export function* watchGetCboDepartments() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_LOAD_CBO_DEPARTMENT, getCboDepartments);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboEmployees(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/standard/load-cbo-employee", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F2080_LOAD_CBO_EMPLOYEE_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get employees error");
    }
}

export function* watchGetCboEmployees() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_LOAD_CBO_EMPLOYEE, getCboEmployees);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboProjects(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/standard/load-cbo-project", data.params);
        if (response && response.data) {
            yield put({
                type: types.W09F2080_LOAD_CBO_PROJECT_SUCCESS,
                data: response.data
            });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get project error");
    }
}

export function* watchGetCboProjects() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_LOAD_CBO_PROJECT, getCboProjects);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteData(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2080/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete data error");
    }
}

export function* watchDeleteData() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_DELETE_DATA, deleteData);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2080/load-popup-default", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_LOAD_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getFormPopup(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2080/load-popup-edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchGetFormPopup() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_LOAD_FORM_POPUP, getFormPopup);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getRequiredFields(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2080/check-required-field");
        yield put({type: types.W09F2080_CHECK_REQUIRED_FIELD_SUCCESS, data: response.data});
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("check required fields error");
    }
}

export function* watchGetRequiredFields() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_CHECK_REQUIRED_FIELD, getRequiredFields);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2080/add", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("save error");
    }
}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_SAVE, save);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* update(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2080/edit", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("update error");
    }
}

export function* watchUpdate() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_UPDATE, update);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getDynamicColumnSalary(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2080/get-dynamic-column-salary",data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get Dynamic Column Salary error",e);
    }
}

export function* watchGetDynamicColumnSalary() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_GET_DYNAMIC_COLUMN_SALARY, getDynamicColumnSalary);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getCboReasons(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2080/load-cbo-reason",data.params);
        if (response && response.data) {
            yield put({type: types.W09F2080_LOAD_CBO_REASON_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo reason error",e);
    }
}

export function* watchGetCboReasons() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_LOAD_CBO_REASON, getCboReasons);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getCboDirectManager(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2080/load-direct-manager",data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo direct manager error",e);
    }
}

export function* watchGetCboDirectManager() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2080_GET_CBO_DIRECT_MANAGER, getCboDirectManager);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
