/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 5/7/2021
 * @Example
 */

export const types = {
    W09F2033_GET_FORM: "W09F2033_GET_FORM",
    W09F2033_GET_BASE_SALARY_CAPTION: "W09F2033_GET_CAPTION",
    W09F2033_GET_BASE_SALARY_CAPTION_SUCCESS: "W09F2033_GET_BASE_SALARY_CAPTION_SUCCESS",
    W09F2033_GET_SALCOEFFICIENT_CAPTION: "W09F2033_GET_SALCOEFFICIENT_CAPTION",
    W09F2033_GET_SALCOEFFICIENT_CAPTION_SUCCESS: "W09F2033_GET_SALCOEFFICIENT_CAPTION_SUCCESS",
    W09F2033_GET_CBO_REASON: "W09F2033_GET_CBO_REASON",
    W09F2033_GET_CBO_REASON_SUCCESS: "W09F2033_GET_CBO_REASON_SUCCESS",
    W09F2033_ADD: "W09F2033_ADD",
    W09F2033_EDIT: "W09F2033_EDIT",
};


export function getBaseSalaryCaption(cb) {
    return {
        type: types.W09F2033_GET_BASE_SALARY_CAPTION,
        cb
    };
}

export function getSalcoefficientCaption(cb) {
    return {
        type: types.W09F2033_GET_SALCOEFFICIENT_CAPTION,
        cb
    };
}

export function getCboReason(cb) {
    return {
        type: types.W09F2033_GET_CBO_REASON,
        cb
    };
}

export function getForm(params, cb) {
    return {
        type: types.W09F2033_GET_FORM,
        params,
        cb
    };
}

export function add(params, cb) {
    return {
        type: types.W09F2033_ADD,
        params,
        cb
    };
}

export function edit(params, cb) {
    return {
        type: types.W09F2033_EDIT,
        params,
        cb
    };
}

