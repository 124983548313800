/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/24/2021
 * @Example
 */

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2091_actions";
import { delay } from "redux-saga";

export default function W09F2091Sagas() {
    return [
        watchGetDataForm(),
        watchGetCboDivision(),
        watchGetCboDepartment(),
        watchGetDataEmployeeByID(),
        watchGetColumn(),
        watchGetCoefficient(),
        watchSave(),
        watchUpdate(),
    ];
}

export function* getDataForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2091/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get data form error");
    }
}

export function* watchGetDataForm() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2091_GET_DATA_FORM, getDataForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboDivision(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2091/load-division");
        if (response && response.data) {
            yield put({type:types.W09F2091_GET_CBO_DIVISION_SUCCESS, data: response.data})
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo division error");
    }
}

export function* watchGetCboDivision() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2091_GET_CBO_DIVISION, getCboDivision);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getCboDepartment(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2091/load-department", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo department error");
    }
}

export function* watchGetCboDepartment() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2091_GET_CBO_DEPARTMENT, getCboDepartment);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getDataEmployeeByID(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2091/load-employee", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get data employee by id error");
    }
}

export function* watchGetDataEmployeeByID() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2091_GET_DATA_EMPLOYEE_BY_ID, getDataEmployeeByID);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getColumn(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2091/load-collum");
        if (response && response.data) {
            yield put({type: types.W09F2091_GET_COLUMN_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get column error");
    }
}

export function* watchGetColumn() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2091_GET_COLUMN, getColumn);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCoefficient(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2091/load-coefficient");
        if (response && response.data) {
            yield put({type: types.W09F2091_GET_COEFFICIENT_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get coefficient error");
    }
}

export function* watchGetCoefficient() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2091_GET_COEFFICIENT, getCoefficient);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2091/save", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("save data error");
    }
}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2091_SAVE, save);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* update(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2091/update", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("update data error");
    }
}

export function* watchUpdate() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2091_UPDATE, update);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
