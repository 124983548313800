/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 2/6/2020
 * @Example
 */

import { types } from "./W09F2060_actions";
const initialState = {
  getDataGrid: [],
  getCboPeriod: [],
  getCboAppStatus: [],
  getCboProDisRewardLevel: [],
  getCboDisRewardForm: [],
  getRewardCaption: [],
  getForm: [],
    getDynamicFields: [],
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case types.W09F2061_GET_DYNAMIC_FIELDS_SUCCESS: {
      return {
        ...state,
        getDynamicFields: action.data
      };
    }
    case types.W09F2060_LOAD_GRID_SUCCESS: {
      return {
        ...state,
        getDataGrid: action.data
      };
    }
    case types.W09F2060_LOAD_APPROVAL_STATUS_SUCCESS: {
      return {
        ...state,
        getCboAppStatus: action.data
      };
    }
    case types.W09F2060_GET_FORM_SUCCESS: {
      return { ...state, getForm: action.data };
    }
    case types.W09F2060_LOAD_CBO_DISREWARD_FORM_SUCCESS: {
      return { ...state, getCboDisRewardForm: action.data };
    }
    case types.W09F2060_LOAD_CBO_PRODIS_REWARD_LEVEL_SUCCESS: {
      return { ...state, getCboProDisRewardLevel: action.data };
    }
    case types.W09F2060_REWARD_CAPTION_SUCCESS: {
      return { ...state, getRewardCaption: action.data }
    }
    default:
      return state;
  }
}
