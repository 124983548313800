/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 2/6/2020
 * @Example
 */

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2060_actions";
import { delay } from "redux-saga";

export default function W09F2060Sagas() {
  return [
    watchGetDataGrid(),
    watchGetForm(),
    watchGetCboAppStatus(),
    watchGetCboDisRewardForm(),
    watchGetCboProDisRewardLevel(),
    watchDeleteData(),
    watchGetRewardCaption(),
    watchEditData(),
    watchSaveData(),
  ];
}

export function* getForm(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f2060/load-form-popup", data.params);
    if (response && response.data) {
      yield put({ type: types.W09F2060_GET_FORM_SUCCESS, data: response.data });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get form error");
  }
}

export function* watchGetForm() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2060_GET_FORM, getForm);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

//get data grid
export function* getDataGrid(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f2060/load-form-data", data.params);
    if (response && response.data) {
      yield put({
        type: types.W09F2060_LOAD_GRID_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get form error");
  }
}

export function* watchGetDataGrid() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2060_LOAD_GRID, getDataGrid);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getRewardCaption(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f2060/load-reward-caption",data.params);
    if (response && response.data) {
      yield put({
        type: types.W09F2060_REWARD_CAPTION_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get reward caption error");
  }
}

export function* watchGetRewardCaption() {
  while (true) {
    const watcher = yield takeLatest(
      types.W09F2060_REWARD_CAPTION,
      getRewardCaption
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

//get Cbo Approval Staus
export function* getCboAppStatus(data) {
  try {
    yield delay(300);
    const response = yield Api.put(
      "/w09f2060/load-cbo-app-status",
      data.params
    );
    if (response && response.data) {
      yield put({
        type: types.W09F2060_LOAD_APPROVAL_STATUS_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get approval status error");
  }
}

export function* watchGetCboAppStatus() {
  while (true) {
    const watcher = yield takeLatest(
      types.W09F2060_LOAD_APPROVAL_STATUS,
      getCboAppStatus
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCboDisRewardForm(data) {
  try {
    yield delay(300);
    const response = yield Api.get("/w09f2060/load-dropdown-disreward-form");
    if (response && response.data) {
      yield put({
        type: types.W09F2060_LOAD_CBO_DISREWARD_FORM_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get dis reward form error");
  }
}

export function* watchGetCboDisRewardForm() {
  while (true) {
    const watcher = yield takeLatest(
      types.W09F2060_LOAD_CBO_DISREWARD_FORM,
      getCboDisRewardForm
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* getCboProDisRewardLevel(data) {
  try {
    yield delay(300);
    const response = yield Api.get("/w09f2161/load-drd-prodis-reward-level");
    if (response && response.data) {
      yield put({
        type: types.W09F2060_LOAD_CBO_PRODIS_REWARD_LEVEL_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get combo prodis reward level error");
  }
}

export function* watchGetCboProDisRewardLevel() {
  while (true) {
    const watcher = yield takeLatest(
      types.W09F2060_LOAD_CBO_PRODIS_REWARD_LEVEL,
      getCboProDisRewardLevel
    );
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* editData(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f2060/edit", data.params);
    if (response && response.data) {
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("edit data error");
  }
}

export function* watchEditData() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2060_EDIT_DATA, editData);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

export function* saveData(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f2060/add", data.params);
    if (response && response.data) {
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("edit data error");
  }
}

export function* watchSaveData() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2060_SAVE_DATA, saveData);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

//deleteData
export function* deleteData(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w09f2060/delete", data.params);
    if (response && response.data) {
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("delete data error");
  }
}

export function* watchDeleteData() {
  while (true) {
    const watcher = yield takeLatest(types.W09F2060_DELETE_DATA, deleteData);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}

