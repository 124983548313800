
import Api from "../../../services/api";
import { cancel, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2081_actions";
import { delay } from "redux-saga";

export default function W09F2081Sagas() {
    return [
        watchGetDynamicColumnSalary(),
        watchLoadGrid(),
        watchChoose(),
    ];
}

export function* getDynamicColumnSalary(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2080/get-dynamic-column-salary",data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get Dynamic Column Salary error",e);
    }
}

export function* watchGetDynamicColumnSalary() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2081_GET_DYNAMIC_COLUMN_SALARY, getDynamicColumnSalary);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* loadGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2081/load-grid",data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get grid error",e);
    }
}

export function* watchLoadGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2081_LOAD_GRID, loadGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* choose(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2081/choose",data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("choose error",e);
    }
}

export function* watchChoose() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2081_CHOOSE, choose);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}